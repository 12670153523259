import React, { useContext, useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Input, Label, Row, } from "reactstrap";
import { Alert } from "react-bootstrap";

import logo from "../../assets/images/certificate/logo.jpg";

import { toast, ToastContainer } from "react-toastify";
//redux
import { useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

import withRouter from "../../Components/Common/withRouter";
import axios from "axios";

const initialState = {
  companyName: "",
  email: "",
  password: "",
  mobileNumber: "",
  companyCategory: "",
  companyProof: null,
  country: "",
  state: "",
  city: "",
  address: "",
};

const Login = (props) => {
  const { error } = useSelector((state) => ({
    error: state.Login.error,
  }));

  const [values, setValues] = useState(initialState);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLogin, setIsLogin] = useState(true);

  // For dynamic dropdowns
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState('');
  const [selectedStateId, setSelectedStateId] = useState('');
  const [selectedCountryId, setSelectedCountryId] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    // Fetch categories
    axios.get(`${process.env.REACT_APP_BASE_URL}/list/categories`)
      .then(response => {
        setCategories(response);
        // console.log("categories:", response);
      })
      .catch(error => {
        console.error("There was an error fetching the categories!", error);
      });

    // Fetch countries
    axios.get(`${process.env.REACT_APP_BASE_URL}/list/ActiveCountries`)
      .then(response => {
        setCountries(response)
        // console.log("countries:", response);
      })
      .catch(error => console.error('Error fetching countries:', error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log("name:", name, "value:", value)
    setValues({ ...values, [name]: value });
    if (name === "country") {
      setSelectedCountryId(value);
      axios.post(`${process.env.REACT_APP_BASE_URL}/list/statesByCountry`, { countryID: value })
        .then(response => {
          setStates(response)
          // console.log("states:", response);
        })
        .catch(error => console.error('Error fetching states:', error));
    }
    else if (name === "state") {
      setSelectedStateId(value);
      axios.post(`${process.env.REACT_APP_BASE_URL}/list/cityByState`, { StateID: value })
        .then(response => {
          setCities(response)
          console.log("cities:", response);
        })
        .catch(error => console.error('Error fetching cities:', error));
    }
    else if (name === "city") {
      setSelectedCityId(value);
    }
    else if (name === "companyCategory") {
      console.log(name, value)
      setValues({ ...values, companyCategory: value });
    }
  };

  const handleFileChange = (e) => {
    setValues({ ...values, companyProof: e.target.files[0] });
  };

  const login = () => {
    setIsSubmit(true);
    setFormErrors(validate(values));
    if (Object.keys(formErrors).length === 0) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/login/company`, values)
        .then((res) => {
          if (res) {
            if (res.company.isActive === false) {
              toast.error("Your account is deactivated. Please contact the administrator.");
              return;
            }
            else {
              console.log('Login successful');

            }
            // console.log("login", JSON.parse(JSON.stringify(res.company)));
            localStorage.setItem("CompanyUser", JSON.stringify(res.company));
            // window.location.replace("/company-dashboard");
            navigate("/company-dashboard");
          } else {
            toast.error("Authentication failed!");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Authentication failed!");
        });
    }
  };

  const register = async () => {
    setIsSubmit(true); // Assuming this controls form submission state
    setFormErrors(validateRegister(values)); // Assuming validateRegister returns errors

    // Check if there are any validation errors
    if (Object.keys(formErrors).length === 0) {
      try {
        // Step 1: Create address data
        const addressObject = {
          CityID: selectedCityId,
          StateID: selectedStateId,
          CountryID: selectedCountryId,
          Address: values.address,
        };

        const addressResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/create/address`, addressObject);
        console.log('Response from createAddress API:', addressResponse.data);

        // Step 2: Prepare formData for company registration
        const formData = new FormData();
        formData.append('mobileNumber', values.mobileNumber);
        formData.append('email', values.email);
        formData.append('password', values.password);
        formData.append('companyName', values.companyName);
        formData.append('companyCategory', values.companyCategory);
        formData.append('companyProof', values.companyProof);
        formData.append('address', addressResponse.data._id); // Assuming addressData has _id

        // Step 3: Submit formData for company creation
        const companyResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/create/company`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // console.log('Response from createCompany API:', companyResponse.data);
        // Assuming res.data.ok is a valid indicator of success
        if (companyResponse) {
          // localStorage.setItem("CompanyUser", companyResponse.company._id);
          localStorage.setItem("CompanyUser", JSON.stringify(companyResponse.company));
          toast.success("Registration successful!");
          setIsLogin(true);
          // navigate("/login");// Set login state if registration is successful
        } else {
          toast.error("Registration failed!");
        }

      } catch (error) {
        console.error('Error during registration:', error);
        toast.error("Registration failed!");
      }
    } else {
      // Handle validation errors if there are any
      console.error('Form validation errors:', formErrors);
      toast.error("Registration failed!");
    }
  };


  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid Email address!";
    }
    if (!values.password) {
      errors.password = "Password is required!";
    }
    return errors;
  };

  const validateRegister = (values) => {
    const errors = validate(values);
    if (!values.companyName) {
      errors.companyName = "Client Name is required!";
    }
    if (!values.mobileNumber) {
      errors.mobileNumber = "Mobile Number is required!";
    } else if (!/^\d{10}$/.test(values.mobileNumber)) {
      errors.mobileNumber = "Invalid Mobile Number!";
    }
    if (!values.companyCategory) {
      errors.companyCategory = "Client Category is required!";
    }
    // if (!values.companyProof) {
    //   errors.companyProof = "A valid Client Proof is required!";
    // }
    if (!values.country) {
      errors.country = "Country is required!";
    }
    if (!values.state) {
      errors.state = "State is required!";
    }
    if (!values.city) {
      errors.city = "City is required!";
    }
    if (!values.address) {
      errors.address = "Address is required!";
    }
    return errors;
  };

  const validClassEmail =
    formErrors.email && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassPassword =
    formErrors.password && isSubmit ? "form-control is-invalid" : "form-control pe-5";

  document.title = " SignIn | EHS Certificate ";
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50"></div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card style={{ marginTop: "10%" }}>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="site-logo">
                        <Link to="index.html">
                          <img
                            style={{ display: "flex", alignItems: "center" }}
                            src={logo}
                            height={"70px"}
                            width={"80px"}
                            alt="EHS Certificate"
                          />
                        </Link>
                      </div>
                    </div>
                    <h5 className="text-primary mt-2">
                      {isLogin ? "Welcome Back Client !" : "Register Client"}
                    </h5>
                    <p className="text-muted">
                      {isLogin ? "Sign in to continue." : "Register to start."}
                    </p>
                  </div>
                  {error && error ? (
                    <Alert color="danger"> {error} </Alert>
                  ) : null}
                  <div className="p-2 mt-4">
                    {isLogin ? (
                      <>
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Email
                          </Label>
                          <Input
                            name="email"
                            className={validClassEmail}
                            placeholder="Enter email"
                            type="email"
                            onChange={handleChange}
                            value={values.email}
                          />
                          {isSubmit && (
                            <p className="text-danger">{formErrors.email}</p>
                          )}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label" htmlFor="password-input">
                            Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={values.password}
                              type={showPassword ? "text" : "Password"}
                              className={validClassPassword}
                              placeholder="Enter Password"
                              onChange={handleChange}
                            />
                            {isSubmit && (
                              <p className="text-danger">{formErrors.password}</p>
                            )}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <i className="ri-eye-off-fill align-middle"></i>
                              ) : (
                                <i className="ri-eye-fill align-middle"></i>
                              )}
                            </button>
                          </div>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="success"
                            className="btn btn-success w-100"
                            type="submit"
                            onClick={login}
                          >
                            Sign In
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mb-3">
                          <Label htmlFor="companyName" className="form-label">
                            Client Name<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="companyName"
                            className={
                              formErrors.companyName && isSubmit
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholder="Enter Client name"
                            type="text"
                            onChange={handleChange}
                            value={values.companyName}
                          />
                          {isSubmit && (
                            <p className="text-danger">{formErrors.companyName}</p>
                          )}
                        </div>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="email" className="form-label">
                                Email<span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="email"
                                className={validClassEmail}
                                placeholder="Enter email"
                                type="email"
                                onChange={handleChange}
                                value={values.email}
                              />
                              {isSubmit && (
                                <p className="text-danger">{formErrors.email}</p>
                              )}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="mobileNumber" className="form-label">
                                Mobile Number<span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="mobileNumber"
                                className={
                                  formErrors.mobileNumber && isSubmit
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                placeholder="Enter mobile number"
                                type="text"
                                onChange={handleChange}
                                value={values.mobileNumber}
                              />
                              {isSubmit && (
                                <p className="text-danger">
                                  {formErrors.mobileNumber}
                                </p>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <div className="mb-3">
                          <Label htmlFor="password-input" className="form-label">
                            Password<span className="text-danger">*</span>
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={values.password}
                              type={showPassword ? "text" : "Password"}
                              className={validClassPassword}
                              placeholder="Enter Password"
                              onChange={handleChange}
                            />
                            {isSubmit && (
                              <p className="text-danger">{formErrors.password}</p>
                            )}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <i className="ri-eye-off-fill align-middle"></i>
                              ) : (
                                <i className="ri-eye-fill align-middle"></i>
                              )}
                            </button>
                          </div>
                        </div>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="companyCategory" className="form-label">
                                Client Category<span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="companyCategory"
                                className={
                                  formErrors.companyCategory && isSubmit
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                type="select"
                                onChange={handleChange}
                                value={values.companyCategory}
                              >
                                <option value="">Select Category</option>
                                {categories &&
                                  categories.map((category, index) => (
                                    <option key={index} value={category._id}>
                                      {category.categoryName}
                                    </option>
                                  ))}
                              </Input>
                              {isSubmit && (
                                <p className="text-danger">
                                  {formErrors.companyCategory}
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="companyProof" className="form-label">
                                Client Proof
                              </Label>
                              <Input
                                name="companyProof"
                                className="form-control"
                                type="file"
                                onChange={handleFileChange}
                              />
                              {isSubmit && (
                                <p className="text-danger">
                                  {formErrors.companyProof}
                                </p>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="country" className="form-label">
                                Country<span className="text-danger"> *</span>
                              </Label>
                              <Input
                                name="country"
                                className={
                                  formErrors.country && isSubmit
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                type="select"
                                onChange={handleChange}
                                value={values.country}
                              >
                                <option value="">Select Country</option>
                                {countries.map((country, index) => (
                                  <option key={index} value={country._id}>
                                    {country.CountryName}
                                  </option>
                                ))}
                              </Input>
                              {isSubmit && (
                                <p className="text-danger">{formErrors.country}</p>
                              )}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="state" className="form-label">
                                State<span className="text-danger"> *</span>
                              </Label>
                              <Input
                                name="state"
                                className={
                                  formErrors.state && isSubmit
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                type="select"
                                onChange={handleChange}
                                value={values.state}
                              >
                                <option value="">Select State</option>
                                {states &&
                                  states.map((state, index) => (
                                    <option key={index} value={state._id}>
                                      {state.StateName}
                                    </option>
                                  ))}
                              </Input>
                              {isSubmit && (
                                <p className="text-danger">{formErrors.state}</p>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="city" className="form-label">
                                City<span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="city"
                                className={
                                  formErrors.city && isSubmit
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                type="select"
                                onChange={handleChange}
                                value={values.city}
                              >
                                <option value="">Select City</option>
                                {cities &&
                                  cities.map((city, index) => (
                                    <option key={index} value={city._id}>
                                      {city.CityName}
                                    </option>
                                  ))}
                              </Input>
                              {isSubmit && (
                                <p className="text-danger">{formErrors.city}</p>
                              )}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="address" className="form-label">
                                Address<span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="address"
                                className={
                                  formErrors.address && isSubmit
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                placeholder="Enter address"
                                type="text"
                                onChange={handleChange}
                                value={values.address}
                              />
                              {isSubmit && (
                                <p className="text-danger">{formErrors.address}</p>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <div className="mt-4">
                          <Button
                            color="primary"
                            className="btn btn-primary w-100"
                            type="submit"
                            onClick={register}
                          >
                            Register
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="mt-3 text-center">
                    <p className="text-muted">
                      {isLogin
                        ? "Don't have an account?"
                        : "Already have an account?"}{" "}
                      <Link
                        to="#"
                        className="fw-medium text-primary"
                        onClick={() => {
                          setIsLogin(!isLogin);
                          setValues(initialState);
                        }}
                      >
                        {isLogin ? "Register" : "Sign In"}
                      </Link>
                    </p>
                    <p>
                      {isLogin && (
                        <Link to="/forgot-password" className="fw-medium text-primary">
                          Forgot password?
                        </Link>
                      )}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
