


import React, { useState, useEffect, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { CompanyContext } from "../../store/CompanyContextV/CompanyContext";
import axios from "axios";
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Select from 'react-select';
import {useLocation} from "react-router-dom";
import {Breadcrumbs, Typography} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { toast,  } from 'react-toastify';

const LookForAgent = () => {
    const { companyData } = useContext(CompanyContext);
    const [data, setData] = useState([]);
    const [companyCategoryId, setCompanyCategoryId] = useState('');
    const [loadingOption, setLoadingOption] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [searchOption, setSearchOption] = useState(null);
    const [searchOptionList, setSearchOptionList] = useState([]);
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pageNo, setPageNo] = useState(0);
    const [column, setcolumn] = useState();
    const [sortDirection, setsortDirection] = useState();
    const [companyName, setcompanyName] = useState('');

    const location = useLocation();
    const { childCertificate } = location.state || {};

    useEffect(() => {
        if(childCertificate){
            setSearchOption(childCertificate)
        }
        setcompanyName(companyData.companyName);
    }, []);

    useEffect(() => {
        if (companyData && companyData.companyCategory) {
            setCompanyCategoryId(companyData.companyCategory);
            fetchCertificates(companyData.companyCategory);
        }
    }, [companyData]);

    const fetchCertificates = async (companyCategoryId) => {
        console.log("Fetching certificates for category:", companyCategoryId);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/list/CertificatesByCategory/${companyCategoryId}`);
            console.log('Response data:', response);
            const fetchedCertificates = response.map(cert => ({
                value: cert._id,
                label: cert.certificateName
            }));
            setSearchOptionList(fetchedCertificates);
        } catch (error) {
            console.error('Error fetching certificates:', error);
            // toast.error('Error fetching certificates')
        }
    };

    const handleSelectChange = (searchOption) => {
        setSearchOption(searchOption);
        console.log("Search Option:", searchOption)
        setQuery(searchOption ? searchOption.label : '');
    };

    const handleChangeOfSelect = (newValue) => {
        setSelectedOptions(newValue);
    };

    useEffect(() => {
        fetchAgents();
    }, [pageNo, perPage, column, sortDirection, query, searchOption]);

    const fetchAgents = async () => {
        console.log("Fetching agents for certificate:", searchOption);
        setLoading(true);
        let skip = (pageNo - 1) * perPage;
        if (skip < 0) skip = 0;

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/list-by-params/agentsByCertificate`, {
                skip: skip,
                per_page: perPage,
                sorton: column,
                sortdir: sortDirection,
                match: "",
                isVerified: true,
                isActive: true,
                _id: searchOption ? searchOption.value : ""
            });

            if (response.data) {
                setData(response.data);
                setTotalRows(response.count);
            } else {
                setData([]);
            }
        } catch (error) {
            setData([]);
            console.error('Error fetching agents:', error);
            // toast.error("Error fetching agents !");
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPerPage(newPerPage);
    };

    const handleSort = (column, sortDirection) => {
        setcolumn(column.sortField);
        setsortDirection(sortDirection);
    };

    const col = [
        {
            name: "Name",
            cell: (row) => row.name,
            sortable: true,
            sortField: "agentName",
            minWidth: "150px",
        },
        {
            name: "Mobile Number",
            cell: (row) => row.mobileNumber,
            sortable: true,
            sortField: "mobileNumber",
            minWidth: "150px",
        },
        {
            name: "Email",
            cell: (row) => row.email,
            sortable: true,
            sortField: "email",
            minWidth: "150px",
        }
    ];

    document.title = "Look for Authority | EHS Certificate";

    return (
        <React.Fragment>
                        {/* <div className="page-content" style={{ marginTop: "4rem", paddingTop: "5.3rem" }}> */}
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Row className="g-4 mb-1">
                                        <Col className="col-sm" lg={5} md={6} sm={6}>
                                            <h6 className="card-title mb-0 fs-4 mt-2">Enter Certificate to get Agents</h6>
                                        </Col>
                                        <Col>
                                            <Select
                                                value={searchOption}
                                                onChange={handleSelectChange}
                                                options={searchOptionList}
                                                placeholder="Search..."
                                                isClearable
                                            />
                                        </Col>
                                    </Row>
                                </CardHeader>

                                <CardBody>
                                    <div className="table-responsive table-card mt-1 mb-1 text-right">
                                        <DataTable
                                            columns={col}
                                            data={data}
                                            progressPending={loading}
                                            sortServer
                                            onSort={handleSort}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationRowsPerPageOptions={[10, 50, 100, totalRows]}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {/* <ToastContainer/> */}
            </div>
        </React.Fragment>
    );
};

export default LookForAgent;
