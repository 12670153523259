import React from "react";
import { Navigate } from "react-router-dom";

import Login from "../pages/Authentication/Login";
import UserProfile from "../pages/Authentication/user-profile";
import CustomerMaster from "../pages/CustomersV/CustomerMaster";
import CustomerCertificates from "../pages/CustomersV/CustomerCertificates";
import CompanyDashboard from "../pages/CompanyV/CompanyDashboard"
import CompanyProfile from "../pages/CompanyV/CompanyProfile";
import IssuedCertificate from "../pages/CompanyV/IssuedCertificate";
import NonIssuedCertificate from "../pages/CompanyV/NonIssuedCertificate";
import LookForAgent from "../pages/CompanyV/lookforagents";
import ForgetPassword from "../pages/Authentication/ForgetPassword";
import CoverPasswCreate from "../pages/Authentication/CoverPasswCreate";
const authProtectedRoutes = [
  { path: "/profile", component: <UserProfile /> },
  { path: "/customer-master", component: <CustomerMaster /> },
  { path: "/customer-certificates", component: <CustomerCertificates />},
  { path : "/company-dashboard", component: <CompanyDashboard/>},
  { path: "/company-profile", component: <CompanyProfile/>},
  { path: "/issued-certificate", component: <IssuedCertificate/>},
  { path: "/non-issued-certificate", component: <NonIssuedCertificate/>},
  { path: "/look-for-agent", component: <LookForAgent/>},
// remove this line
  {
    path: "/",
    exact: true,
    component: <Navigate to="/company-dashboard" />,
  },
  { path: "*", component: <Navigate to="/company-dashboard" /> },
];

const publicRoutes = [
  // { path: "/dashboard", component: <DashboardCrm /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPassword /> },
  { path: "/reset-password", component: <CoverPasswCreate/>},
];

export { authProtectedRoutes, publicRoutes };
