
// import React, { useEffect, useState } from 'react';
// import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
// import axios from 'axios';
// import { useDropzone } from 'react-dropzone';

// const IssueCertificateModal = ({ companyCategory, isOpen, toggleModal, companyId, certificates, onCertificateIssued }) => {
//   const [formData, setFormData] = useState({
//     certificateId: '',
//     issueDate: '',
//     expireDate: '',
//     remarks: '',
//     isActive: true
//   });

//   const [file, setFile] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [options, setOptions] = useState([]);

//   const fetchCertificatesByCategory = async () => {
//     try {
//       // Replace with actual endpoint
//       const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/list/CertificatesByCategory`, { category: companyCategory });
//       console.log('Response:', response);
//       const fetchedCertificates = response.map(cert => ({
//         value: cert._id,
//         label: cert.certificateName
//       }));
//       setOptions(fetchedCertificates);
//     } catch (error) {
//       console.error('Error fetching certificates:', error);
//     }
//   };

//   useEffect(() => {
//     fetchCertificatesByCategory();
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       const formDataWithFile = new FormData();
//       formDataWithFile.append('companyId', companyId);
//       formDataWithFile.append('certificateId', formData.certificateId);
//       formDataWithFile.append('issueDate', formData.issueDate);
//       formDataWithFile.append('expireDate', formData.expireDate);
//       formDataWithFile.append('remarks', formData.remarks);
//       formDataWithFile.append('isActive', formData.isActive);
//       formDataWithFile.append('certificateFile', file);

//       // Replace with actual endpoint
//       const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/create/certificateForCompany`, formDataWithFile, {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       });

//       onCertificateIssued(response.issuedCertificate);
//       toggleModal(); // Close the modal after successful issuance
//     } catch (error) {
//       console.error('Error issuing certificate:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleCancel = () => {
//     toggleModal(); // Close the modal on cancel
//   };

//   const { getRootProps, getInputProps } = useDropzone({
//     accept: '.pdf, .jpg, .jpeg, .png',
//     multiple: false,
//     onDrop: (acceptedFiles) => {
//       setFile(acceptedFiles[0]);
//     }
//   });

//   return (
//     <Modal isOpen={isOpen} toggle={toggleModal}>
//       <ModalHeader toggle={toggleModal}>Issue Certificate</ModalHeader>
//       <ModalBody>
//         <Form onSubmit={handleSubmit}>
//           <FormGroup>
//             <Label for="certificateId">Certificate</Label>
//             <Input
//               type="select"
//               name="certificateId"
//               id="certificateId"
//               value={formData.certificateId}
//               onChange={handleChange}
//               required
//             >
//               <option value="">Select Certificate</option>
//               {options.map((certificate) => (
//                 <option key={certificate.value} value={certificate.value}>{certificate.label}</option>
//               ))}
//             </Input>
//           </FormGroup>
//           <FormGroup>
//             <Label for="issueDate">Issue Date</Label>
//             <Input
//               type="date"
//               name="issueDate"
//               id="issueDate"
//               value={formData.issueDate}
//               onChange={handleChange}
//               required
//             />
//           </FormGroup>
//           <FormGroup>
//             <Label for="expireDate">Expiration Date</Label>
//             <Input
//               type="date"
//               name="expireDate"
//               id="expireDate"
//               value={formData.expireDate}
//               onChange={handleChange}
//               required
//             />
//           </FormGroup>
//           <FormGroup>
//             <Label for="remarks">Remarks</Label>
//             <Input
//               type="textarea"
//               name="remarks"
//               id="remarks"
//               value={formData.remarks}
//               onChange={handleChange}
//             />
//           </FormGroup>
//           <FormGroup>
//             <Label for="certificateFile">Certificate File</Label>
//             <div {...getRootProps()} style={{ cursor: 'pointer', padding: '10px', border: '1px dashed #ccc' }}>
//               <input {...getInputProps()} />
//               {file ? (
//                 <p>{file.name}</p>
//               ) : (
//                 <p>Drag 'n' drop file here, or click to select file</p>
//               )}
//             </div>
//           </FormGroup>
//           <ModalFooter>
//             <Button color="primary" type="submit" disabled={!formData.certificateId || loading}>
//               {loading ? 'Issuing...' : 'Issue Certificate'}
//             </Button>
//             <Button color="secondary" onClick={handleCancel} disabled={loading}>
//               Cancel
//             </Button>
//           </ModalFooter>
//         </Form>
//       </ModalBody>
//     </Modal>
//   );
// };

// export default IssueCertificateModal;
import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';

const IssueCertificateModal = ({ companyCategory, isOpen, toggleModal, companyId, certificates, onCertificateIssued }) => {
  const [formData, setFormData] = useState({
    certificateId: '',
    issueDate: '',
    expireDate: '',
    remarks: '',
    isActive: true
  });

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchCertificatesByCategory = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/list/CertificatesByCategory`, { category: companyCategory });
      console.log('Response:', response); // Log the response data to check its structure

      // Ensure the response data is an array and contains valid certificate objects
      if (Array.isArray(response)) {
        const fetchedCertificates = response.map(cert => ({
          value: cert._id,
          label: cert.certificateName
        }));
        setOptions(fetchedCertificates);
      } else {
        console.error('Invalid response data format:', response);
      }
    } catch (error) {
      console.error('Error fetching certificates:', error);
    }
  };

  useEffect(() => {
    fetchCertificatesByCategory();
  }, [companyCategory]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const formDataWithFile = new FormData();
      formDataWithFile.append('companyId', companyId);
      formDataWithFile.append('certificateId', formData.certificateId);
      formDataWithFile.append('issueDate', formData.issueDate);
      formDataWithFile.append('expireDate', formData.expireDate);
      formDataWithFile.append('remarks', formData.remarks);
      formDataWithFile.append('isActive', formData.isActive);
      formDataWithFile.append('certificateFile', file);

      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/create/certificateForCompany`, formDataWithFile, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      onCertificateIssued(response.issuedCertificate);
      toggleModal(); // Close the modal after successful issuance
      setFormData({
        certificateId: '',
        issueDate: '',
        expireDate: '',
        remarks: '',
        isActive: true
      });
      setFile(null);
    } catch (error) {
      console.error('Error issuing certificate:', error);
      // Handle error state
    } finally {
      setLoading(false);
      toggleModal(); // Close the modal after issuancefile
    }
  };

  const handleCancel = () => {
    setFormData({
      certificateId: '',
      issueDate: '',
      expireDate: '',
      remarks: '',
      isActive: true
    });
    setFile(null);
    toggleModal(); // Close the modal
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf, .jpg, .jpeg, .png',
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    }
  });

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Issue Certificate</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="certificateId">Certificate</Label>
            <Input
              type="select"
              name="certificateId"
              id="certificateId"
              value={formData.certificateId}
              onChange={handleChange}
              required
            >
              <option value="">Select Certificate</option>
              {options.map((certificate) => (
                <option key={certificate.value} value={certificate.value}>{certificate.label}</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="issueDate">Issue Date</Label>
            <Input
              type="date"
              name="issueDate"
              id="issueDate"
              value={formData.issueDate}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="expireDate">Expiration Date</Label>
            <Input
              type="date"
              name="expireDate"
              id="expireDate"
              value={formData.expireDate}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="remarks">Remarks</Label>
            <Input
              type="textarea"
              name="remarks"
              id="remarks"
              value={formData.remarks}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="certificateFile">Certificate File</Label>
            <div {...getRootProps()} style={{ cursor: 'pointer', padding: '10px', border: '1px dashed #ccc' }}>
              <input {...getInputProps()}/>
              {file ? (
                <p>{file.name}</p>
              ) : (
                <p>Drag 'n' drop file here, or click to select file</p>
              )}
            </div>
          </FormGroup>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={!formData.certificateId || loading}>
              {loading ? 'Issuing...' : 'Issue Certificate'}
            </Button>
            <Button color="secondary" onClick={handleCancel} disabled={loading}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default IssueCertificateModal;
