import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { createAgent, getAgentsForCertificate } from '../../functions/AgentV/Agent';
import { Select } from 'antd';
import { toast } from 'react-toastify';

const AddCertificate = ({ companyCategoryId, isOpen, toggleModal, companyId, certificates, onCertificateIssued }) => {
    const [formData, setFormData] = useState({
        certificateId: '',
        issueDate: '',
        expireDate: '',
        remarks: '',
        agent: {},
        isActive: true
    });

    const [createNewAgent, setCreateNewAgent] = useState(false);
    const [isLifetime, setIsLifetime] = useState(false);
    const [newAgent, setNewAgent] = useState({
        name: '',
        email: '',
        mobileNumber: ''
    });

    const [certificateAgents, setCertificateAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const fetchCertificatesByCategory = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/list/CertificatesByCategory/${companyCategoryId}`);
            if (Array.isArray(response)) {
                const fetchedCertificates = response.map(cert => ({
                    value: cert._id,
                    label: cert.certificateName
                }));
                setOptions(fetchedCertificates);
            } else {
                console.error('Invalid response data format:', response);
                // toast.error("Certificates failed to fetch !");
            }
        } catch (error) {
            console.error('Error fetching certificates:', error);
            // toast.error("Certificates failed to fetch !");
        }
    };

    useEffect(() => {
        fetchCertificatesByCategory();
    }, [companyCategoryId]);

    useEffect(() => {
        if (formData.certificateId) {
            getAgentsForCertificate(formData.certificateId)
                .then((resp) => {
                    const agents = resp.data.map(agent => ({
                        value: agent._id,
                        label: agent.name,
                        agentData: agent
                    }));
                    setCertificateAgents(agents);
                })
                .catch((error) => {
                    console.error('Error fetching agents:', error);
                    // toast.error("Agents failed to fetch !");
                });
        }
    }, [formData.certificateId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    }
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setLoading(true);

    //     let newAgentId = null;

    //     if (createNewAgent) {
    //         try {
    //             const values = {
    //                 name: newAgent.name,
    //                 email: newAgent.email,
    //                 mobileNumber: newAgent.mobileNumber,
    //                 isActive: true,
    //                 isVerified: false,
    //                 password: "12345678",
    //                 proof: null,
    //                 providingCertificates: [formData.certificateId]
    //             };

    //             const res = await createAgent(values);
    //             newAgentId = res.agent._id;
    //         } catch (error) {
    //             console.error('Error creating new agent:', error.message);
    //             toast.error("Failed to create new agent!");
    //         }
    //     } else {
    //         console.log("hretr,",selectedAgent._id)
    //         newAgentId = selectedAgent ? selectedAgent._id : null;
    //     }

    //     try {
    //         const formDataWithFile = new FormData();
    //         formDataWithFile.append('companyId', companyId);
    //         formDataWithFile.append('certificateId', formData.certificateId);
    //         formDataWithFile.append('issueDate', formData.issueDate);
    //         formDataWithFile.append('expireDate', isLifetime ? null : formData.expireDate);
    //         formDataWithFile.append('remarks', formData.remarks);
    //         formDataWithFile.append('isActive', formData.isActive);
    //         formDataWithFile.append('agent', newAgentId);
    //         formDataWithFile.append('certificateFile', file);

    //         const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/create/certificateForCompany`, formDataWithFile, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data'
    //             }
    //         });

    //         setFormData({
    //             certificateId: '',
    //             issueDate: '',
    //             expireDate: '',
    //             remarks: '',
    //             isActive: true,
    //         });
    //         setFile(null);
    //         setSelectedAgent(null);
    //         setNewAgent({
    //             name: '',
    //             email: '',
    //             mobileNumber: ''
    //         });

    //         toggleModal(); // Close the modal after successful issuance
    //     } catch (error) {
    //         console.error('Error issuing certificate:', error);
    //         setFormData({
    //             certificateId: '',
    //             issueDate: '',
    //             expireDate: '',
    //             remarks: '',
    //             isActive: true,
    //         });
    //         setFile(null);
    //         setSelectedAgent(null);
    //         setNewAgent({
    //             name: '',
    //             email: '',
    //             mobileNumber: ''
    //         });
    //         toast.error('Error Issuing certificate');
    //     } finally {
    //         setLoading(false);
    //         toggleModal(); // Close the modal after issuance
    //     }
    // };

    const [errors, setErrors] = useState({ email: '', mobileNumber: '' });

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email) ? '' : 'Invalid email';
    };

    const validateMobileNumber = (mobileNumber) => {
        const mobileNumberRegex = /^[0-9]{10}$/;
        return mobileNumberRegex.test(mobileNumber) ? '' : 'Invalid mobile number';
    };

    const handleEmailChange = (e) => {
        const email = e.target.value;
        setNewAgent({ ...newAgent, email });
        setErrors({ ...errors, email: validateEmail(email) });
    };

    const handleMobileNumberChange = (e) => {
        const mobileNumber = e.target.value;
        setNewAgent({ ...newAgent, mobileNumber });
        setErrors({ ...errors, mobileNumber: validateMobileNumber(mobileNumber) });
    };


    const handleCheckBox = (e) => {
        setCreateNewAgent(!createNewAgent);
        if (createNewAgent) {
            setNewAgent({
                name: '',
                email: '',
                mobileNumber: ''
            });
        } else {
            setSelectedAgent(null);
        }
    };

    const handleCancel = () => {
        setFormData({
            certificateId: '',
            issueDate: '',
            expireDate: '',
            remarks: '',
            isActive: true
        });
        setFile(null);
        setSelectedAgent(null);
        toggleModal(); // Close the modal
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: '.pdf, .jpg, .jpeg, .png',
        multiple: false,
        onDrop: (acceptedFiles) => {
            setFile(acceptedFiles[0]);
        }
    });

    return (
        <Modal isOpen={isOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Issue Certificate</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="certificateId">Certificate <span className="text-danger">*</span></Label>
                        <Input
                            type="select"
                            name="certificateId"
                            id="certificateId"
                            value={formData.certificateId}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Certificate</option>
                            {options.map((certificate) => (
                                <option key={certificate.value} value={certificate.value}>{certificate.label}</option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="issueDate">Issue Date <span className="text-danger">*</span></Label>
                        <Input
                            type="date"
                            name="issueDate"
                            id="issueDate"
                            value={formData.issueDate}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="expireDate">Expiration Date</Label>
                        <Input
                            type="date"
                            name="expireDate"
                            id="expireDate"
                            value={formData.expireDate}
                            onChange={handleChange}
                            disabled={isLifetime}
                        />
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" checked={isLifetime} onChange={() => setIsLifetime(!isLifetime)} />{' '}
                            Lifetime
                        </Label>
                    </FormGroup>
                    <FormGroup>
                        <Label for="remarks">Remarks</Label>
                        <Input
                            type="textarea"
                            name="remarks"
                            id="remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                    <Label for="certificateFile">Certificate File <span className='text-danger'>*</span> (should be in pdf, jpg, jpeg, or png format, Max Size: 5mb)</Label>
                        <div {...getRootProps()} style={{ cursor: 'pointer', padding: '10px', border: '1px dashed #ccc' }}>
                            <input {...getInputProps()}/>
                            {file ? (
                                <p>{file.name}</p>
                            ) : (
                                <p>Drag 'n' drop file here, or click to select file</p>
                            )}
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label for="agent">Agent</Label>
                        <Select
                            showSearch
                            disabled={createNewAgent}
                            value={selectedAgent}
                            style={{ width: '100%' }}
                            placeholder="Search and select agent"
                            optionFilterProp="children"
                            onChange={(value, option) => setSelectedAgent(option.agentData)}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {certificateAgents.map(agent => (
                                <Select.Option key={agent.value} value={agent.value} agentData={agent.agentData}>
                                    {agent.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </FormGroup>
                    {selectedAgent && (
                        <div>
                            <p><strong>Name:</strong> {selectedAgent.name}</p>
                            <p><strong>Mobile:</strong> {selectedAgent.mobileNumber}</p>
                            <p><strong>Email:</strong> {selectedAgent.email}</p>
                        </div>
                    )}
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" checked={createNewAgent} onChange={handleCheckBox} />{' '}
                            Can't find? Create new
                        </Label>
                    </FormGroup>
                    {createNewAgent && (
                        <>
                            {/* <FormGroup>
                                <Label for="newAgentName">Name <span className='text-danger'>*</span></Label>
                                <Input
                                    type="text"
                                    name="newAgentName"
                                    id="newAgentName"
                                    value={newAgent.name}
                                    onChange={(e) => setNewAgent({ ...newAgent, name: e.target.value })}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="newAgentEmail">Email <span className='text-danger'>*</span></Label>
                                <Input
                                    type="email"
                                    name="newAgentEmail"
                                    id="newAgentEmail"
                                    value={newAgent.email}
                                    onChange={(e) => setNewAgent({ ...newAgent, email: e.target.value })}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="newAgentMobileNumber">Mobile Number <span className='text-danger'>*</span></Label>
                                <Input
                                    type="text"
                                    name="newAgentMobileNumber"
                                    id="newAgentMobileNumber"
                                    value={newAgent.mobileNumber}
                                    onChange={(e) => setNewAgent({ ...newAgent, mobileNumber: e.target.value })}
                                    required
                                />
                            </FormGroup> */}
                            <FormGroup>
                <Label for="newAgentName">Name <span className='text-danger'>*</span></Label>
                <Input
                    type="text"
                    name="newAgentName"
                    id="newAgentName"
                    value={newAgent.name}
                    onChange={(e) => setNewAgent({ ...newAgent, name: e.target.value })}
                    required
                />
            </FormGroup>
            <FormGroup>
                <Label for="newAgentEmail">Email <span className='text-danger'>*</span></Label>
                <Input
                    type="email"
                    name="newAgentEmail"
                    id="newAgentEmail"
                    value={newAgent.email}
                    onChange={handleEmailChange}
                    invalid={errors.email !== ''}
                    required
                />
                <FormFeedback>{errors.email}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="newAgentMobileNumber">Mobile Number <span className='text-danger'>*</span></Label>
                <Input
                    type="text"
                    name="newAgentMobileNumber"
                    id="newAgentMobileNumber"
                    value={newAgent.mobileNumber}
                    onChange={handleMobileNumberChange}
                    invalid={errors.mobileNumber !== ''}
                    required
                />
                <FormFeedback>{errors.mobileNumber}</FormFeedback>
            </FormGroup>
                        </>
                    )}

                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={!formData.certificateId || !formData.issueDate || (createNewAgent && (!newAgent.name || !newAgent.email || !newAgent.mobileNumber)) || loading}>
                            {loading ? 'Issuing...' : 'Issue Certificate'}
                        </Button>
                        <Button color="secondary" onClick={handleCancel} disabled={loading}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default AddCertificate;
