import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { Button } from 'reactstrap'; // Assuming you are using Reactstrap for styling
import AddCertificate from './AddCertificate';
import {CompanyContext} from "../../store/CompanyContextV/CompanyContext";
import EditCertificateModal from "./EditCertificateModal"; // Adjust the path as per your project structure
import RemoveCertificateModal from './RemoveCertificateModal';
import {Breadcrumbs, Typography} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext"; // Import the RemoveCertificateModal component
import { toast , ToastContainer} from 'react-toastify';

const IssuedCertificate = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [companyId, setCompanyId] = useState();
    const [companyCategoryId, setCompanyCategoryId] = useState('');
    const [companyName, setCompanyName] = useState('');

    const { companyData } = useContext(CompanyContext);

    useEffect(() => {
        if (!showModal) {
            fetchData(1, perPage);
        }
        if(companyData)
        setCompanyName(companyData.companyName);
    }, [showModal]);

    useEffect(() => {
        fetchData(1, perPage);
        console.log("Company Data: ", companyData);
        setCompanyCategoryId(companyData?.companyCategory);
    }, [companyData]);

    const fetchData = async (page, perPage) => {
        setLoading(true);
        try {
            setCompanyId(companyData._id);
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/list/certificatesByCompany/${companyData._id}`);
            const certificates = response.certificatesWithPdfUrl.map(cert => ({
                ...cert,
                agentName: cert.agent.agentName || 'No Agent'
            }));
            console.log("Resp: ", response);
            setData(certificates);
            setTotalRows(certificates.length);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data: ", error);
            // toast.error("Error fetching data !");
            setLoading(false);
        }
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handlePageChange = (page) => {
        fetchData(page, perPage);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchData(page, newPerPage);
    };

    // Edit Certificate Modal
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editingCertificate, setEditingCertificate] = useState(null);

    const handleEditModalClose = () => {
        setEditModalOpen(false);
        setEditingCertificate(null);
        fetchData(1, perPage); // Fetch certificates again to get updated data
    };

    const handleEditClick = (certificate) => {
        setEditingCertificate(certificate);
        setEditModalOpen(true);
    };

    const handleViewDocument = (certificate) => () => {
        console.log("View Document: ", certificate);
        window.open(certificate.certificatePDFUrl, '_blank');
    }

    // Remove Certificate Modal
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [certificateToRemove, setCertificateToRemove] = useState(null);

    const handleRemoveClick = (certificate) => {
        setCertificateToRemove(certificate);
        setRemoveModalOpen(true);
    };

    const handleRemoveConfirm = async () => {
        console.log("Removing certificate: ", certificateToRemove)
        if (certificateToRemove) {
            try {
                await axios.delete(`h${process.env.REACT_APP_BASE_URL}/auth/delete/companyCertificate/${certificateToRemove._id}`);
                fetchData(1, perPage); // Refresh the data after deletion
                setRemoveModalOpen(false);
                setCertificateToRemove(null);
            } catch (error) {
                console.error("Error removing certificate: ", error);
                // toast.error('Error deleting Certificate');
            }
        }
    };

    const columns = [
        {
            name: 'Certificate Name',
            selector: row => row.certificateName,
            sortable: true,
        },
        {
            name: 'Issue Date',
            selector: row => new Date(row.issueDate).toLocaleDateString(),
            sortable: true,
        },
        {
            name: 'Expire Date',
            selector: row => row.expireDate===null||row.expireDate==="1970-01-01T00:00:00.000Z" ?'Lifetime': new Date(row.expireDate).toLocaleDateString() ,
            sortable: true,
        },
        {
            name: 'Remarks',
            selector: row => row.remarks,
        },
        {
            name: 'Agent Name',
            selector: row => row.agent[0]?.name || 'No Agent',
            sortable: true,
        },
        {
            name: 'Agent Number',
            selector: row => row.agent[0]?.mobileNumber || 'No Agent',
            sortable: true,
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                {/* // <div className="d-flex gap-2"> */}
                    {/* <div className="edit">
                        <button
                            className="btn btn-sm btn-success edit-item-btn"
                            onClick={() => handleEditClick(row)}
                        >
                            Edit
                        </button>
                    </div>
                    <div className="remove">
                        <button
                            className="btn btn-sm btn-danger remove-item-btn"
                            onClick={() => handleRemoveClick(row)}
                        >
                            Remove
                        </button>
                    </div> */}
                    
                    <Button 
                    color="success"
                    className="view-btn" 
                    style={{ margin: '0 4px', fontSize: '0.875rem', lineHeight: '1.25rem' }}
                    onClick={() => row.certificatePDFUrl && window.open(row.certificatePDFUrl, '_blank')}
                    disabled={!row.certificatePDFUrl}
                >
                    View
                </Button>
                </>
            ),
            minWidth: "16rem",
        }
    ];


    document.title = "Issued Certificates | EHS Certificate";

    return (
        // <div className="container mt-4" style={{ marginTop: "4rem", paddingTop: "7.8rem" }}>
        <div className="container" style={{ paddingTop: "6rem"}}>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h4>Issued Certificates</h4>
                    <Button color="success" className="add-btn me-1" onClick={toggleModal}>
                        <i className="ri-add-line align-bottom me-1"></i>
                        Add Certificate
                    </Button>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <DataTable
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            pagination
                            // paginationServer
                            // paginationTotalRows={totalRows}
                            // paginationRowsPerPageOptions={[10, 50, 100, totalRows]}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>

            {/* Add Certificate Modal */}
            <AddCertificate
                companyCategoryId={companyCategoryId}
                companyId={companyId}
                isOpen={showModal}
                toggleModal={toggleModal}
            />
            <EditCertificateModal
                isOpen={editModalOpen}
                toggleModal={handleEditModalClose}
                certificate={editingCertificate}
                companyCategoryId={companyCategoryId}
            />
            <RemoveCertificateModal
                isOpen={removeModalOpen}
                toggleModal={() => setRemoveModalOpen(!removeModalOpen)}
                onConfirm={handleRemoveConfirm}
            />
            {/* <ToastContainer/> */}
        </div>
    );
};

export default IssuedCertificate;
