import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Box,
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Breadcrumbs
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { CompanyContext } from "../../store/CompanyContextV/CompanyContext";
import {listActiveCountry, listCityByState, listStatesByCountry} from "../../functions/Location/Location";
import {Button} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {Link} from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';

const CompanyProfile = () => {
    const [company, setCompany] = useState({
        name: '',
        number: '',
        email: '',
        category: '',
        address: ''
    });
    const [categories, setCategories] = useState([]);
    const [fullAddress, setFullAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [CountryID, setCountryID] = useState('');
    const [StateID, setStateID] = useState('');
    const [CityID, setCityID] = useState('');
    const [editing, setEditing] = useState(false);

    const { companyData } = useContext(CompanyContext);

    useEffect(() => {
        setCompanyId(companyData?._id);
        if (companyId) {
            fetchCompanyDetails(companyId)
                .then(() => getFullAddress(companyData.address))
        } else {
            console.error("No companyId found in local storage.");
            // toast.error("Company fetching failed!");
        }
        fetchCategories();
    }, [companyId, companyData?._id]);

    const fetchCompanyDetails = async (companyId) => {
        setCompanyId(companyData._id);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/get/companyById/${companyData._id}`);
            if (response.company) {
                const companyDetails = response.company;
                setCompany({
                    name: companyDetails.companyName,
                    number: companyDetails.mobileNumber,
                    email: companyDetails.email,
                    category: companyDetails.companyCategory,
                    address: companyDetails.address
                });
            } else {
                console.error("No company details found in the response.");
                // toast.error("Company fetching failed!");
            }
        } catch (error) {
            console.error("Error fetching company details:", error);
            // toast.error("comapny fetching failed!");
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/list/categories`);
            if (response) {
                setCategories(response)
            } else {
                console.error("No categories found in the response.");
                // toast.error("Category Fetching failed!");
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
            // toast.error("Category fetching failed!");
        }
    };

    const getCategoryName = () => {
        const category = categories.find(cat => cat._id === companyData.companyCategory);
        return category? category.categoryName : 'Unknown Category';
    };

    const getFullAddress = async (addressId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/get/addressById/${addressId}`);
            if (response) {
                console.log("address", response[0])
                setFullAddress(response[0].Address);
                setCity(response[0].cityName);
                setState(response[0].stateName);
                setCountryID(response[0].CountryID);
                setStateID(response[0].StateID);
                setCityID(response[0].CityID);
                setCountry(response[0].countryName);
            } else {
                console.error("No address details found in the response.");
                // toast.error("Error fetching Address!");
            }
        } catch (error) {
            console.error("Error fetching address details:", error);
            // toast.error('Error fetching Address')
        }
    };

    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    useEffect(() => {
        listActiveCountry().then(res => {
            setCountries(res)
        })
    }, [])
    useEffect(() => {
        CountryID && listStatesByCountry(CountryID).then(res => {
            setStates(res)
        })
    }, [CountryID]);
    useEffect(() => {
        StateID && listCityByState(StateID).then(res => {
            setCities(res)
        })
    }, [StateID, CountryID]);

    const handleEdit = () => {
        setEditing(true);
    };

    const handleCancel = () => {
        setEditing(false);
        fetchCompanyDetails(companyId);
        getFullAddress(companyData.address);
    };

    const handleSubmit = async () => {
        try {
            const addressResponse = await axios.put(`${process.env.REACT_APP_BASE_URL}/update/address/${company.address}`, {
                Address: fullAddress,
                CityID: CityID,
                StateID: StateID,
                CountryID: CountryID
            });

            if (addressResponse) {
                const updatedCompany = {
                    mobileNumber: company.number,
                    email: company.email,
                    companyName: company.name,
                    companyCategory: company.category,
                    address: addressResponse.data._id,
                    isActive: companyData.isActive,
                    isVerified: companyData.isVerified,
                    // password: companyData.password,
                };

                const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/update/company/${companyData._id}`, updatedCompany);
                if(resp.company){
                    localStorage.setItem('CompanyUser', JSON.stringify(resp.company));
                    window.location.reload()
                }
                setEditing(false);
                fetchCompanyDetails(companyId);
                getFullAddress(addressResponse.address._id);
            } else {
                console.error("No address created in the response.");
                // toast.error("Address creation failed!");
            }
        } catch (error) {
            console.error("Error creating address or updating company:", error);
            // toast.error("Error updating Company !");
        }
    };
    return (
        <div className="page-content" style={{ marginTop: "4rem", paddingTop: "0.2rem" }}>
            <Container fluid>
                <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                    <Grid container spacing={3} mt={3} maxWidth="600px">
                        <Grid item xs={6}>
                            <TextField
                                label="Company Name"
                                variant="outlined"
                                fullWidth
                                value={company.name}
                                InputProps={{readOnly: !editing}}
                                onChange={(e) => setCompany({...company, name: e.target.value})}
                                sx={{backgroundColor: 'white'}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Phone Number"
                                variant="outlined"
                                fullWidth
                                value={company.number}
                                InputProps={{readOnly: !editing}}
                                onChange={(e) => setCompany({...company, number: e.target.value})}
                                sx={{backgroundColor: 'white'}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Email"
                                variant="outlined"
                                fullWidth
                                value={company.email}
                                InputProps={{readOnly: !editing}}
                                onChange={(e) => setCompany({...company, email: e.target.value})}
                                sx={{backgroundColor: 'white'}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="category-label">Category</InputLabel>
                                <Select
                                    labelId="category-label"
                                    value={company.category}
                                    onChange={(e) => setCompany({...company, category: e.target.value})}
                                    label="Category"
                                    disabled={true}
                                    sx={{backgroundColor: 'white'}}
                                >
                                    {categories && categories.map((category) => (
                                        <MenuItem key={category._id} value={category._id}>
                                            {category.categoryName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Address"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={2}
                                value={fullAddress}
                                InputProps={{readOnly: !editing}}
                                onChange={(e) => setFullAddress(e.target.value)}
                                sx={{backgroundColor: 'white'}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="country-label">Country</InputLabel>
                                <Select
                                    labelId="country-label"
                                    value={CountryID}
                                    onChange={(e) => {
                                        setCountryID(e.target.value)
                                    }}
                                    label="Country"
                                    disabled={!editing}
                                    sx={{backgroundColor: 'white'}}
                                >
                                    {countries && countries.map((country) => (
                                        <MenuItem key={country._id} value={country._id}>
                                            {country.CountryName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="state-label">State</InputLabel>
                                <Select
                                    labelId="state-label"
                                    value={StateID}
                                    onChange={(e) => {
                                        setStateID(e.target.value)
                                    }}
                                    label="State"
                                    disabled={!editing || !CountryID}
                                    sx={{backgroundColor: 'white'}}
                                >
                                    {states && states.map((state) => (
                                        <MenuItem key={state._id} value={state._id}>
                                            {state.StateName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="city-label">City</InputLabel>
                                <Select
                                    labelId="city-label"
                                    value={CityID}
                                    onChange={(e) => {
                                        setCityID(e.target.value)
                                    }}
                                    label="City"
                                    disabled={!editing || !StateID}
                                    sx={{backgroundColor: 'white'}}
                                >
                                    {cities && cities.map((city) => (
                                        <MenuItem key={city._id} value={city._id}>
                                            {city.CityName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between">
                                {editing && (
                                    <>
                                        <Button color="success" className={"add-btn me-1"} onClick={handleSubmit}>
                                            Submit
                                        </Button>
                                        <Button variant="outlined" className="btn btn-sm btn-danger remove-item-btn"
                                                color="red" onClick={handleCancel}>
                                            Cancel
                                        </Button>
                                    </>
                                )}
                                {!editing && (
                                    <Button variant="contained" color="primary" onClick={handleEdit}>
                                        Edit
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <ToastContainer/>
        </div>
    );
};

export default CompanyProfile;