import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const RemoveCertificateModal = ({ isOpen, toggleModal, onConfirm }) => (
    <Modal isOpen={isOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Confirm Removal</ModalHeader>
        <ModalBody>
            Are you sure you want to remove this certificate?
        </ModalBody>
        <ModalFooter>
            <Button color="danger" onClick={onConfirm}>Remove</Button>
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
    </Modal>
);

export default RemoveCertificateModal;
