import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import {CompanyContext} from "../../store/CompanyContextV/CompanyContext";
import {useNavigate} from "react-router-dom";
import {Breadcrumbs, Typography} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { toast } from 'react-toastify';

const NonIssuedCertificate = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [companyId, setCompanyId] = useState('');
    const [companyName, setCompanyName] = useState('');

    const { companyData } = useContext(CompanyContext);
    const navigate = useNavigate();


    useEffect(() => {
        fetchData(1, perPage);
        if(companyData)
        setCompanyName(companyData.companyName);
    }, [companyData]);

    const fetchData = async (page, perPage) => {
        // console.log("ID", companyDataID)
        setLoading(true);
        try {
            const storedCompanyUser = JSON.parse(localStorage.getItem('CompanyUser'));
            setCompanyId(storedCompanyUser._id);
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/list/suggestedCertificatesForCompany/${companyData._id}`);

            const certificates = response.result.map(cert => ({
                ...cert,
            }));
            setData(certificates);
            setTotalRows(certificates.length);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data: ", error);
            // toast.error('Error Fetching Data')
            setLoading(false);
        }
    };


    const handlePageChange = (page) => {
        fetchData(page, perPage);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchData(page, newPerPage);
    };

    const columns = [
        {
            name: 'Certificate Name',
            selector: row => row.certificateName,   
            sortable: true,
        },
        {
            name: 'Search for Agent',
            cell:(row)=>(
                <div className="d-flex gap-2">
                    <div className="edit">
                        <button
                            className="btn btn-sm btn-success edit-item-btn"
                            onClick={()=>{
                                navigate('/look-for-agent',{ state: { childCertificate: {label: row.certificateName, value: row._id} } });
                                //on tap go to look for agent page with current certificate id as selected
                            }}
                        >
                            View Agents
                        </button>
                    </div>
                </div>
            ),
            // minWidth: "16rem",

        }
  
    ]; 

    document.title = "Suggested Certificates | EHS Certificate";

    return (
        <div className="container" style={{ paddingTop: "6rem"}}>
            <div className="card">
                <div className="card-header">
                    <h4>Non-Issued Certificates</h4>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <DataTable
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationRowsPerPageOptions={[10, 50, 100, totalRows]}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
            {/* <ToastContainer/> */}
        </div>
    );
};

export default NonIssuedCertificate;


