import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';

const EditCertificateModal = ({ certificate, isOpen, toggleModal, onUpdateCertificate }) => {
  const [formData, setFormData] = useState({
    issueDate: certificate ? (certificate.issueDate ? new Date(certificate.issueDate).toISOString().substr(0, 10) : '') : '',
    expireDate: certificate ? (certificate.expireDate ? new Date(certificate.expireDate).toISOString().substr(0, 10) : '') : '',
    remarks: certificate ? certificate.remarks : '',
  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  // Update formData when certificate prop changes
  useEffect(() => {
    if (certificate) {
      setFormData({
        issueDate: certificate.issueDate ? new Date(certificate.issueDate).toISOString().substr(0, 10) : '',
        expireDate: certificate.expireDate ? new Date(certificate.expireDate).toISOString().substr(0, 10) : '',
        remarks: certificate.remarks || '',
      });
    }
  }, [certificate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { issueDate, expireDate, remarks } = formData;
      const formDataWithFile = new FormData();
      formDataWithFile.append('issueDate', issueDate);
      formDataWithFile.append('expireDate', expireDate);
      formDataWithFile.append('remarks', remarks);
      if (file) {
        formDataWithFile.append('certificateFile', file);
      }
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/update/certificateForCompany/${certificate._id}`, formDataWithFile, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      certificate.issueDate = issueDate;
      certificate.expireDate = expireDate;
      certificate.remarks = remarks;
      if (file) {
        certificate.certificateFile = file;
      }

      // Handle success response
      console.log('Certificate updated:', response.data);

      // Pass updated certificate back to parent component
      onUpdateCertificate(response.data.updatedCertificate);

      toggleModal(); // Close the modal after successful update
    } catch (error) {
      console.error('Error updating certificate:', error);
    } finally {
      setLoading(false);
      toggleModal(); // Close the modal on cancel
    }
  };

  const handleCancel = () => {
    toggleModal(); // Close the modal on cancel
  };

  // Dropzone configuration
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf, .jpg, .jpeg, .png',
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    }
  });

  // Ensure certificate exists before rendering
  if (!certificate) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Edit Certificate</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="issueDate">Issue Date</Label>
            <Input
              type="date"
              name="issueDate"
              id="issueDate"
              value={formData.issueDate}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="expireDate">Expiration Date</Label>
            <Input
              type="date"
              name="expireDate"
              id="expireDate"
              value={formData.expireDate}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="remarks">Remarks</Label>
            <Input
              type="textarea"
              name="remarks"
              id="remarks"
              value={formData.remarks}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="certificateFile">Current Certificate File</Label>
            <div {...getRootProps()} style={{ cursor: 'pointer', padding: '10px', border: '1px dashed #ccc' }}>
              <input {...getInputProps()} />
              {file ? (
                <p>{file.name}</p>
              ) : (
                <p>Drag 'n' drop file here, or click to select file</p>
              )}
            </div>
          </FormGroup>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={loading}>
              {loading ? 'Updating...' : 'Update Certificate'}
            </Button>
            <Button color="secondary" onClick={handleCancel} disabled={loading}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default EditCertificateModal;
