import React from "react";

//import Scss
import "./assets/scss/themes.scss";
// import "./assets/scss/plugins/_toastify.scss";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css';

//imoprt Route
import Route from "./Routes";

function App() {
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
