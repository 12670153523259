import React, {useContext, useEffect, useState} from 'react';
import {CompanyContext} from "../../store/CompanyContextV/CompanyContext";
import { Container } from 'reactstrap';
import { Box } from '@mui/material';
// import {CompanyContext} from "../../store/CompanyContextV/CompanyContext";
import BannerImg from "../../assets/images/certificate/banner.jpg"

const CompanyDashboard = () => {

    const { companyData } = useContext(CompanyContext);
    // console.log(companyData.name)
    const [companyName, setcompanyName] = useState('');
    useEffect(() => {
        if(companyData)
            setcompanyName(companyData.companyName);
    }, [companyData])
    document.title ='Dashboard | EHS Certificate'
    return (
        

        <Container className={"page-content"}>
            <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                <divv style={{display:"flex", flexDirection: "column", alignItems:"center"}} >
                    <h2>Welcome, {companyName? companyName:""}</h2>
                    <img src={BannerImg} alt="banner" style={{width: "80%", margin: "2rem"}}/>
                </divv>
            </Box>
        </Container>
    );
};

export default CompanyDashboard;