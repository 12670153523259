import axios from "axios"

export const createAddress = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/create/address`,
    values
  );
}

export const createCompany = async (values) => {
    return await axios.post(
        `${process.env.REACT_APP_BASE_URL}/create/company`,
        values
    );
}

export const updateAddress = async (id, values) => {
    return await axios.put(
        `${process.env.REACT_APP_BASE_URL}/update/address/${id}`,
        values
    );
}

export const updateCompany = async (id, values) => {
    return await axios.put(
        `${process.env.REACT_APP_BASE_URL}/auth/update/company/${id}`,
        values
    );
}

export const getCompanyCertificates = async (id) => {
    return await axios.get(
        `${process.env.REACT_APP_BASE_URL}/auth/list/certificatesByCompany/${id}`
    );
}

export const deleteCompany = async (id) => {
    return await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/auth/delete/company/${id}`
    );
}


export const deleteCompanyCertificate = async (id) => {
    return await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/auth/delete/companyCertificate/${id}`
    );
}