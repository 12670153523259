// src/Context/AppContext.js
import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
    const [companyData, setCompanyData] = useState(null);
    useEffect(() => {
        const storedCompanyUser = JSON.parse(localStorage.getItem('CompanyUser'));
        setCompanyData(storedCompanyUser);
    }, []);

    return (
        <CompanyContext.Provider value={{ companyData, setCompanyData }}>
            {children}
        </CompanyContext.Provider>
    );
};
